import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch'
        },
    },
    inputs: {
        width: '100%',
        display: 'column',
        justifyContent: 'flex-left'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    }
}));

const LeadForm = ({ data, handleNext }) => {

    const classes = useStyles();

    //Lead input fields
    const [leadFirstName, setLeadFirstName] = React.useState('');
    const [leadLastName, setLeadLastName] = React.useState('');
    const [leadEmail, setLeadEmail] = React.useState('');

    //lead input validation errors
    const [leadFirstNameError, setLeadFirstNameError] = React.useState(false);
    const [leadLastNameError, setLeadLastNameError] = React.useState(false);
    const [leadEmailError, setLeadEmailError] = React.useState(false);

    const formValide = () => {
        let leadFirstNameValid = false;
        let leadLastNameValid = false;
        let leadEmailValid = false;

        const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!leadFirstName.trim()) {
            setLeadFirstNameError(true)
        } else {
            leadFirstNameValid = true
        };

        if (!leadLastName.trim()) {
            setLeadLastNameError(true)
        } else {
            leadLastNameValid = true
        };

        if (!leadEmail) {
            setLeadEmailError(true)
        } else if (!validRegex.test(leadEmail)) {
            console.log('RegEx Email: ', leadEmail.match(validRegex))
            setLeadEmailError(true)
        } else {
            leadEmailValid = true
        };

        return (leadFirstNameValid && leadLastNameValid && leadEmailValid)
    };

    const submitHubSpotForm = () => {

        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/2970512/578d3206-fdc8-4d3d-bf33-687ff8049947?hapikey=49b8738b-47af-466a-abc8-c313db122dde'
        // Example request JSON:

        var str2bool = (value) => {
            if (value && typeof value === "string") {
                if (value.toLowerCase() === "yes") return true;
                if (value.toLowerCase() === "no") return false;
            }
        }

        var submitData = {

            "submittedAt": Date.now(),
            "fields": [
                {
                    "name": "email",
                    "value": leadEmail
                },
                {
                    "name": "firstname",
                    "value": leadFirstName
                },
                {
                    "name": "lastname",
                    "value": leadLastName
                },
                {
                    "name": "web_apps",
                    "value": str2bool(data.questWebApp.value)
                },
                {
                    "name": "mobile_apps",
                    "value": str2bool(data.questMobileApp.value)
                },
                {
                    "name": "apis",
                    "value": (data.questAPITypes.options.restful.value ? "rest;" : "") + (data.questAPITypes.options.graphql.value ? "graphql;" : "") + (data.questAPITypes.options.soap.value ? "soap;" : "") + (data.questAPITypes.options.xml.value ? "xml;" : "")
                },
                {
                    "name": "cloud_platforms",
                    "value": (data.questClouds.options.aws.value ? "aws;" : "") + (data.questClouds.options.azure.value ? "azure;" : "") + (data.questClouds.options.gcp.value ? "gcp;" : "")
                },
                {
                    "name": "cloud_services",
                    "value": (data.questCloudServices.options.compute.value ? "compute;" : "") + (data.questCloudServices.options.storage.value ? "storage;" : "") + (data.questCloudServices.options.serverless.value ? "serverless;" : "") + (data.questCloudServices.options.databases.value ? "databases;" : "")
                },
                {
                    "name": "framework",
                    "value": (data.questFramework.options.react.value ? "react;" : "") + (data.questFramework.options.angular.value ? "angular;" : "") + (data.questFramework.options.vue.value ? "vue;" : "") + (data.questFramework.options.other.value ? "frameworkOther;" : "")
                },
                {
                    "name": "compliance",
                    "value": (data.questCompliance.options.pci.value ? "pci;" : "") + (data.questCompliance.options.hipaa.value ? "hipaa;" : "") + (data.questCompliance.options.ccpa.value ? "ccpa;" : "") + (data.questCompliance.options.gdpr.value ? "gdpr;" : "") + (data.questCompliance.options.soc2.value ? "soc2;" : "") + (data.questCompliance.options.owasp.value ? "owasp;" : "") + (data.questCompliance.options.iso.value ? "iso;" : "") + (data.questCompliance.options.other.value ? "compOther;" : "")
                }
            ],
            "context": {
                //"hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                "pageUri": "www.example.com/page",
                "pageName": "Example page"
            },
            "legalConsentOptions": { // Include this object when GDPR options are enabled
                "consent": {
                    "consentToProcess": true,
                    "text": "I agree to allow Example Company to store and process my personal data.",
                    "communications": [
                        {
                            "value": true,
                            "subscriptionTypeId": 999,
                            "text": "I agree to receive marketing communications from Example Company."
                        }
                    ]
                }
            }
        }

        var final_data = JSON.stringify(submitData)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
                handleNext();
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                //alert(xhr.responseText); // Returns a 400 error the submission is rejected.
                setLeadEmailError(true)
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                //alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                //alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }



        // Sends the request 
        xhr.send(final_data)
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        setLeadFirstNameError(false);
        setLeadLastNameError(false);
        setLeadEmailError(false);

        if (formValide() === true) {
            submitHubSpotForm();
            //handleNext();
        };
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography color="textPrimary" component={'span'} variant="h6" gutterBottom >
                        Your contact information:
                    </Typography>
                    <div className={classes.inputs} >
                        <form noValidate onSubmit={handleSubmit} >
                            <TextField className={classes.inputs} required error={leadFirstNameError} helperText={leadFirstNameError === true ? "Please enter your first name" : null} id="standard-required" name="firstname" label="First Name:" value={leadFirstName} onChange={(e) => setLeadFirstName(e.target.value)} />
                            <TextField className={classes.inputs} required error={leadLastNameError} helperText={leadLastNameError === true ? "Please enter your last name" : null} id="standard-required" name="lastname" label="Last Name:" value={leadLastName} onChange={(e) => setLeadLastName(e.target.value)} />
                            <TextField className={classes.inputs} required error={leadEmailError} helperText={leadEmailError === true ? "Please enter a valid email address" : null} id="standard-required" name="email" label="Email:" value={leadEmail} onChange={(e) => setLeadEmail(e.target.value)} />
                            <Button type="submit" className={classes.button} variant="contained" color="primary" >
                                Submit
                            </Button>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default LeadForm;