import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GaugeChart from 'react-gauge-chart';
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AccordLayer from './AccordLayer';
//import Accordion from '@material-ui/core/Accordion';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
//import AccordionActions from '@material-ui/core/AccordionActions';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#ccc",
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    card: {
        backgroundColor: "transparent",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        width: '100%',
        backgroundColor: "transparent",
        height: 140
    },
    media: {
        height: 140,
    },
    cardfont: {
        color: 'black'
    },
    wrap: {
        height: '100%'
    }
}));

const Results = ({ data }) => {

    const content = {
        client: {
            high: {
                summary: 'Organizations with modern application stacks have a larger dependency on APIs that handle data. ',
                main: 'Customers that leverage modern software frameworks and develop mobile, IoT, and single-page web applications (SPAs) often have a substantial number of dynamic APIs. On average, customers using REST and GraphQL APIs will see between 40-70% of all API operations with security exposures and compliance policy violations. '
            },
            medium: {
                summary: 'Organizations that are moving to more modern application stacks see a significant increase in APIs and need to start considering how to best inventory APIs. ',
                main: 'Customers with some mobile and modern web applications but minimal dynamic APIs hosted in the public cloud will generally have a medium-level of API security exposure within the client layers of their applications.'
            },
            low: {
                summary: 'Organizations that have yet to introduce modern application stacks have lower dependency on APIs. ',
                main: 'Customers with less than two mobile apps and legacy Web 2.0 applications that are hosted on-premise tend to have relatively static API attack surfaces. If this describes your client applications, then you most likely have low client-layer API exposures.'
            },
            more: {
                url: 'https://www.datatheorem.com/resources/videos/how-modern-data-breaches-attack-every-layer-of-the-application-stack'
            }
        },
        api: {
            high: {
                summary: 'Maintaining a global API inventory is critical as most modern API data breaches occur as organizations are unaware of exposed APIs. ',
                main: 'Large organizations that depend on Data Theorem for API security often have between 550-750 publicly accessible APIs and between 2,200-3,800 API operations. As a result, the attack surface of these types of organizations is substantial. Further, on average, GraphQL APIs have 3X more urgent security violations compared to REST APIs.. '
            },
            medium: {
                summary: 'Developing a global API inventory should be a priority. Most modern API data breaches occur as organizations are unaware of exposed APIs. ',
                main: 'SOAP-based XML web services have some API security exposures but organizations that have little-to-zero cloud adoption and minimal usage of REST APIs will generally have a medium level of API security exposure.'
            },
            low: {
                summary: 'Legacy APIs such as SOAP and XML still require security consideration, however threat exposure is lower. ',
                main: 'Customers that have no cloud-hosted API services often have a lower API exposure level. Further, customers that have no REST nor GraphQL APIs, will tend to have less dynamic applications with lower API exposures.'
            },
            more: {
                url: 'https://www.datatheorem.com/resources/webinars/how-to-automate-an-api-security-program/response'
            }
        },
        cloud: {
            high: {
                summary: 'With a large investment in cloud infrastructure maintaining an API inventory becomes challenging. Detection of shadow APIs and misconfiguration of cloud services is necessary to reduce exposure. ',
                main: 'On average, customers with multi-cloud adoption have between 40-60% of their assets categorized as “shadow assets.” These cloud assets include databases, storage, containers, and serverless cloud functions like Lambda. The explosive growth of shadow cloud assets create challenging new API attack surfaces for Enterprise organizations.'
            },
            medium: {
                summary: 'As your organization continues to invest into the cloud, detection of shadow APIs will become a common challenge. ',
                main: 'Customers that host their applications primarily on-premise with only one public cloud often have a medium-level of API exposure. Further, customers with little-to-no usage of serverless cloud functions, often have a medium-level of API exposure.'
            },
            low: {
                summary: 'Most applications leverage third-party code that connects to cloud infrastructure via APIs. Although your applications do not leverage the cloud today, it is very likely that your application data is being sent to third-party clouds. ',
                main: 'Customers that have little to no cloud adoption for their own application will often have third-party software supply chain risks. Despite a lack of cloud-native application development, open-source libraries and software-development kits will often introduce cloud data vulnerabilities via third-party API connections.'
            },
            more: {
                url: 'https://www.datatheorem.com/resources/webinars/app-centric-cloud-approach/response'
            }
        },
        compliance: 'As a compliance regulated organization, having an API security program is critical to ensuring that sensitive data exposure is avoided. '
    };


    //Calculate the over all threat for a stack layer
    const calculateLayerThreat = type => {

        var threatValue = 0;
        var totalValue = 0;

        //Check total quest answers
        Object.entries(data).forEach(([key, value]) => {
            if (value.layer === type) {
                if (value.options === undefined) { //Check radio question answers
                    if (value.value === 'yes') {
                        threatValue = threatValue + value.point;
                    }
                } else {
                    Object.entries(value.options).forEach(([key, option]) => { //Check checkbox values
                        if (option.value === true) {
                            threatValue = threatValue + option.point;
                        }
                    });
                }
            }
        });

        //Check total point value
        Object.entries(data).forEach(([key, value]) => {
            if (value.layer === type) {
                if (value.options === undefined) { //Check radio question answers
                    totalValue = totalValue + value.point;
                } else {
                    Object.entries(value.options).forEach(([key, option]) => { //Check checkbox values
                        totalValue = totalValue + option.point;
                    });
                }
            }
        });

        return threatValue / totalValue;
    };

    //Caculate the overall threat
    const calculateOverallThreat = () => {

        var totalThreat = 0;

        totalThreat = totalThreat + calculateLayerThreat('client');
        totalThreat = totalThreat + calculateLayerThreat('api');
        totalThreat = totalThreat + calculateLayerThreat('cloud');
        //totalThreat = totalThreat + calculateLayerThreat('other');

        return totalThreat / 3;
    }

    //Calculate the threat level
    const calculateThreatLevel = type => {

        const threatScore = (type === 'all' ? calculateOverallThreat() : calculateLayerThreat(type));

        return (Number(threatScore) > .6 ? 'High' : (Number(threatScore) > .2 ? 'Medium' : 'Low'));
    }

    const classes = useStyles();

    return (
        <React.Fragment >
            <div className={classes.wrap}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    Overall API Attack Service Exposure
                                </Typography>
                                <GaugeChart
                                    id="gauge-chart"
                                    nrOfLevels={10}
                                    arcsLength={[0.1, 0.1, 0.2, 0.2, 0.4]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    percent={calculateOverallThreat()}
                                    hideText={true}
                                    needleColor="#fff"
                                />
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    {calculateThreatLevel('all')}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography color="textPrimary" gutterBottom>
                            {(calculateThreatLevel('other') !== 'Low' ? content.compliance : null)}
                            {(calculateThreatLevel('client') === 'High' ? content.client.high.summary : calculateThreatLevel('client') === 'Medium' ? content.client.medium.summary : content.client.low.summary)}
                            {(calculateThreatLevel('api') === 'High' ? content.api.high.summary : calculateThreatLevel('api') === 'Medium' ? content.api.medium.summary : content.api.low.summary)}
                            {(calculateThreatLevel('cloud') === 'High' ? content.cloud.high.summary : calculateThreatLevel('cloud') === 'Medium' ? content.cloud.medium.summary : content.cloud.low.summary)}
                        </Typography>
                    </Grid>
                </Grid>
                <AccordLayer layer={'client'} layerThreat={calculateLayerThreat('client')} layerThreatLevel={calculateThreatLevel('client')} content={(calculateThreatLevel('client') === 'High' ? content.client.high.main : calculateThreatLevel('client') === 'Medium' ? content.client.medium.main : content.client.low.main)} url={content.client.more.url} />
                <AccordLayer layer={'api'} layerThreat={calculateLayerThreat('api')} layerThreatLevel={calculateThreatLevel('api')} content={(calculateThreatLevel('api') === 'High' ? content.api.high.main : calculateThreatLevel('api') === 'Medium' ? content.api.medium.main : content.api.low.main)} url={content.api.more.url} />
                <AccordLayer layer={'cloud'} layerThreat={calculateLayerThreat('cloud')} layerThreatLevel={calculateThreatLevel('cloud')} content={(calculateThreatLevel('cloud') === 'High' ? content.cloud.high.main : calculateThreatLevel('cloud') === 'Medium' ? content.cloud.medium.main : content.cloud.low.main)} url={content.cloud.more.url} />
            </div>
        </React.Fragment >
    );
}

export default Results;