import './App.css';
import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CalcStepper from './Components/CalcStepper'

//Material UI Theme
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff'
        },
        secondary: {
            main: '#68c4fc'
        },
        text: {
            primary: '#fff',
            secondary: '#68c4fc'
        },
    }
})

//Main App
function App() {
    return (
        <div className="App">
            <div className="content">
                <div id="logo"></div>
                <ThemeProvider theme={theme}>
                    <Typography component="h1" variant="h4" align="center" color="textPrimary">API Attack Surface Calculator</Typography>
                    <CalcStepper />
                </ThemeProvider>
            </div>
        </div>
    );
}
export default App;
