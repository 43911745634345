import React, { useRef, /*useLayoutEffect*/ } from 'react'
//import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'


function Box(props) {

    // This reference will give us direct access to the mesh
    const ref = useRef()
    // Set up state for the hovered and active state
    //const [hovered, setHover] = useState(false)
    //const [active, setActive] = useState(false)
    /*            onClick={(e) => setActive(!active)}
            onPointerOver={(e) => setHover(true)}
            onPointerOut={(e) => setHover(false)} */

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => {
        ref.current.rotation.y = ref.current.rotation.y += 0.003
    })
    return (
        <mesh
            {...props}
            ref={ref}
            scale={1}>
            <boxGeometry args={props.args} />
            <meshPhysicalMaterial color={props.color} opacity={props.opacity} transparent={props.transparent} clearcoast={props.clearcoast} {...props} />
        </mesh>


    )
}

/*
function Line({ start, end }) {
    const ref = useRef()
    useLayoutEffect(() => {
        ref.current.geometry.setFromPoints([start, end].map((point) => new THREE.Vector3(...point)))
    }, [start, end])
    return (
        <line ref={ref}>
            <bufferGeometry />
            <lineDashedMaterial
                color="#fff"
                linewidth={1}
                scale={1}
                dashSize={1}
                gapSize={1} />

        </line>

    )
}
*/
export default function LayerCake() {

    return (
        <Canvas camera={{ position: [4, 2, 4], zoom: 2 }}>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <Box position={[0, 1, 0]} color='#598aef' opacity={0.9} transparent={true} clearcoast={1} args={/*layer === 'client' ? [4, .1, 4] : */[3, .1, 3]} />
            <Box position={[0, .5, 0]} color='#fcc13d' opacity={0.9} transparent={true} clearcoast={1} args={/*layer === 'api' ? [4, .1, 4] : */[3, .1, 3]} />
            <Box position={[0, 0, 0]} color="#48aa58" opacity={0.9} transparent={true} clearcoast={1} args={/*layer === 'cloud' ? [4, .1, 4] : */[3, .1, 3]} />
        </Canvas>
    )
}