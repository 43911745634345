import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid'
import LayerCake from './LayerCake';

//Styles
const useStyles = makeStyles((theme) => ({
    '.MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
    },
    content: {
        width: '100%',
        marginLeft: theme.spacing(4),
    },
    question: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left'
    },
    options: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-left',
    }
}));

const QuestRadio = ({ data, setData }) => {

    //Material UI Styles
    const classes = useStyles();

    const [radioValue, setRadioValue] = React.useState(data);

    React.useEffect(() => { setRadioValue(data) }, [data, setRadioValue]);

    React.useEffect(() => { setData(radioValue) }, [radioValue, setData]);

    const handleChange = e => {
        setRadioValue({
            ...radioValue,
            value: e.target.value
        })
    };

    return (
        <div>
            <React.Fragment>
                <div className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} md={3}>
                            <div height='400px'>
                                <LayerCake layer={radioValue.layer} />
                            </div>
                        </Grid>
                        <Grid item xs={9} md={9}>
                            <div className={classes.question}>
                                <Typography color="textPrimary" component={'span'} variant="h6" gutterBottom >
                                    {radioValue.question}
                                </Typography>
                            </div>
                            <div className={classes.options}>
                                <FormControl color="secondary" component="fieldset">
                                    <FormLabel component="legend">Select one</FormLabel>
                                    <RadioGroup aria-label={radioValue.label} name={radioValue.name} onChange={handleChange} value={radioValue.value}>
                                        <FormControlLabel value='yes' control={<Radio />} label="Yes" color="textPrimary" />
                                        <FormControlLabel value='no' control={<Radio />} label="No" color="textPrimary" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        </div>
    );
}

export default QuestRadio;