import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid'
import LayerCake from './LayerCake';

//Material UI Styles
const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        marginLeft: theme.spacing(4),
    },
    question: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left'
    },
    optionsLabel: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-left'
    },
    options: {
        width: '100%',
        display: 'row',
        justifyContent: 'flex-left'
    }
}));

const QuestCheck = ({ data, setData }) => {

    //Material UI Styles
    const classes = useStyles();

    const [questOptions, setQuestOptions] = React.useState(data);

    React.useEffect(() => { setQuestOptions(data) }, [data, setQuestOptions]);

    React.useEffect(() => { setData(questOptions) }, [questOptions, setData]);

    const handleChange = (event) => {

        setQuestOptions({
            ...questOptions,
            options: {
                ...questOptions.options,
                [event.target.name]: {
                    ...questOptions.options[event.target.name],
                    value: event.target.checked
                }
            },
        });
    }

    return (
        <React.Fragment>
            <div className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={3} md={3}>
                        <div height='400px'>
                            <LayerCake layer={questOptions.layer} />
                        </div>
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <Typography color="textPrimary" component={'span'} className={classes.question} variant="h6" gutterBottom>
                            {questOptions.question}
                        </Typography>
                        <div className={classes.options}>
                            <FormLabel className={classes.optionsLabel} color="secondary" component="legend">Select all that apply</FormLabel>
                            <FormGroup row>
                                {Object.entries(questOptions.options).map(([key, value]) =>
                                    <FormControlLabel key={key} control={<Checkbox name={key} />} color="textPrimary" label={value.label} checked={value.value} onChange={handleChange} />
                                )}
                            </FormGroup>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}
export default QuestCheck;