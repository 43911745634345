import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import QuestRadio from './QuestRadio';
import QuestCheck from './QuestCheck';
import LeadForm from './LeadForm';
import Results from './Results';

//Material UI Styles
const useStyles = makeStyles((theme) => ({
    stepper: {
        backgroundColor: "transparent",
        padding: theme.spacing(3, 0, 3),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    step: {
        color: '#68c4fc'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',

    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    }
}));

//Main Stepper
const CalcStepper = () => {

    //Material UI Styles
    const classes = useStyles();

    const [appData, setAppData] = React.useState(
        {
            questWebApp: {
                value: 'no',
                question: 'Do you have public web apps with APIs?',
                name: 'questWebApp',
                label: 'Web',
                point: 7,
                layer: "client"
            },
            questMobileApp: {
                value: 'no',
                question: 'Do you have public mobile apps with APIs?',
                label: 'Mobile',
                name: 'questMobileApp',
                point: 7,
                layer: "client"
            },
            questAPITypes: {
                label: 'APIs',
                question: 'Do you have public web apps with APIs?',
                name: 'APIs',
                layer: 'api',
                options: {
                    restful: {
                        label: 'RESTful',
                        value: false,
                        point: 7
                    },
                    graphql: {
                        label: 'GraphQL',
                        value: false,
                        point: 2
                    },
                    xml: {
                        label: 'XML',
                        value: false,
                        point: 1
                    },
                    soap: {
                        label: 'SOAP',
                        value: false,
                        point: 1
                    }
                }
            },
            questClouds: {
                label: 'Clouds',
                question: 'What public clouds do your web and mobile apps leverage?',
                name: 'Clouds',
                layer: 'cloud',
                options: {
                    aws: {
                        label: 'AWS',
                        value: false,
                        point: 3
                    },
                    azure: {
                        label: 'Azure',
                        value: false,
                        point: 3
                    },
                    gcp: {
                        label: 'GCP',
                        value: false,
                        point: 3
                    }
                }
            },
            questCloudServices: {
                label: 'Cloud Services',
                question: 'What public clouds services do you apps leverage?',
                name: 'Cloud Services',
                layer: 'cloud',
                options: {
                    compute: {
                        label: 'Compute',
                        value: false,
                        point: 3
                    },
                    storage: {
                        label: 'Storage',
                        value: false,
                        point: 1
                    },
                    serverless: {
                        label: 'Serverless',
                        value: false,
                        point: 3
                    },
                    databases: {
                        label: 'Databases',
                        value: false,
                        point: 1
                    }
                }
            },
            questFramework: {
                label: 'Framework',
                question: 'What web app frameworks do you leverage?',
                name: 'Web App Framework',
                layer: 'client',
                options: {
                    react: {
                        label: 'React',
                        value: false,
                        point: 3
                    },
                    angular: {
                        label: 'Angular',
                        value: false,
                        point: 3
                    },
                    vue: {
                        label: 'Vue',
                        value: false,
                        point: 3
                    },
                    other: {
                        label: 'Other',
                        value: false,
                        point: 3
                    }
                }
            },
            questCompliance: {
                label: 'Compliance',
                question: 'What regulatory and compliance standards are you do focus on?',
                name: 'compliance',
                layer: 'other',
                options: {
                    pci: {
                        label: 'PCI',
                        value: false,
                        point: 7
                    },
                    hipaa: {
                        label: 'HIPAA',
                        value: false,
                        point: 7
                    },
                    soc2: {
                        label: 'SOC2',
                        value: false,
                        point: 2
                    },
                    iso: {
                        label: 'ISO',
                        value: false,
                        point: 2
                    },
                    ccpa: {
                        label: 'CCPA',
                        value: false,
                        point: 7
                    },
                    gdpr: {
                        label: 'GDPR',
                        value: false,
                        point: 7
                    },
                    owasp: {
                        label: 'OWASP',
                        value: false,
                        point: 1
                    },
                    other: {
                        label: 'Other',
                        value: false,
                        point: 1
                    },
                }
            }
        }
    );


    //Get the number of steps from the data
    const getSteps = () => {
        return Object.values(appData).map(step => step.label);
    }

    //Get app data from quest comp
    const getData = (name) => {

        return appData[name];
    }
    //Active Step
    const [activeStep, setActiveStep] = React.useState(0);

    //Set app data from quest comp
    const setData = (name) => (data) => {
        const allData = appData;

        allData[name] = {
            ...allData[name],
            ...data
        };

        setAppData(allData);
    }

    //Next Step
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    //Previous Step
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Reset Steps
    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = getSteps();

    return (
        < React.Fragment >
            <div className={classes.layout}>
                <Stepper activeStep={activeStep} className={classes.stepper} >
                    {steps.map((label) => (
                        <Step key={label} className={classes.step}>
                            <StepLabel >{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 ? (
                    <QuestRadio data={getData('questWebApp')} setData={setData('questWebApp')} />
                ) : activeStep === 1 ? (
                    <QuestRadio data={getData('questMobileApp')} setData={setData('questMobileApp')} />
                ) : activeStep === 2 ? (
                    <QuestCheck data={getData('questAPITypes')} setData={setData('questAPITypes')} />
                ) : activeStep === 3 ? (
                    <QuestCheck data={getData('questClouds')} setData={setData('questClouds')} />
                ) : activeStep === 4 ? (
                    <QuestCheck data={getData('questCloudServices')} setData={setData('questCloudServices')} />
                ) : activeStep === 5 ? (
                    <QuestCheck data={getData('questFramework')} setData={setData('questFramework')} />
                ) : activeStep === 6 ? (
                    <QuestCheck data={getData('questCompliance')} setData={setData('questCompliance')} />
                ) : null
                }
                {activeStep === steps.length ? (
                    <div>
                        <LeadForm data={appData} handleNext={handleNext} />
                    </div>
                ) : activeStep === steps.length + 1 ? (
                    <div>
                        <Results data={appData} />
                        <Button className={classes.button} color="primary" onClick={handleReset}>
                            Start Over
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                        <Button className={classes.button} variant="contained" color="primary" onClick={handleNext} >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                )}
            </div>
        </React.Fragment >
    );
}

export default CalcStepper;